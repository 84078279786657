<template>
  <div v-if="!this.$func.checkPermission('ธนาคาร')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="bank_action">
      <div class="row mb-3">
        <div class="col col-sm-6">
          <div class="d-flex">
            <span class="my-auto mr-2" style="width: 50px">วันที่</span>
            <fg-input class="m-0 w-100">
              <el-date-picker
                v-model="date"
                type="date"
                placeholder="วันที่"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                @change="getBankAction()"
              >
              </el-date-picker>
            </fg-input>
          </div>
        </div>
        <div class="col">
          <ModalTransfer />
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-4"
          v-for="bank in bank_action"
          :key="bank.number"
        >
          <div class="card mb-2" v-loading="loadingBank">
            <div class="card-body row">
              <div class="col-4 col-sm-3 d-flex">
                <img
                  :src="`/static/img/${
                    bank.bank
                      ? `bank-${bank.bank.toLowerCase()}.png`
                      : 'tuktuk888-logo.webp'
                  }`"
                  alt=""
                  class="mw-100 rounded m-auto"
                />
              </div>
              <div class="col my-auto">
                <div class="fw-bold">{{ bank.type }}</div>
                <div>
                  {{ bank.name }} | <b>{{ bank.number }}</b>
                </div>
                <div></div>
                <hr class="m-2" />
                <!-- <div>ยอดคงเหลือ:</div> -->
                <div>
                  <div class="fw-bold d-flex">
                    <div class="w-50">รับเงิน:</div>
                    <div class="fw-bold">
                      {{ bank.tranfer.toLocaleString() }}
                    </div>
                  </div>
                  <div class="fw-bold d-flex">
                    <div class="w-50">ถอนเงิน:</div>
                    <div class="fw-bold">
                      {{ bank.withdraw.toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-3 mt-0">ธนาคาร</h3>
        <card>
          <div slot="header">
            <button @click="addBank()" type="button" class="btn btn-primary">
              เพิ่มข้อมูล
            </button>
          </div>
          <div class="row">
            <div class="col-6 col-sm-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col text-right">
              <el-button
                type="danger"
                size="default"
                @click="getBank()"
                :loading="loading"
                ><i class="fa fa-refresh" aria-hidden="true"></i
              ></el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableData"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="50" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="200" label="บัญชี">
                  <template v-slot="props">
                    <div class="d-flex w-100">
                      <img
                        :src="`/static/img/bank-${props.row.name.toLowerCase()}.png`"
                        alt=""
                        class="bank mr-2 my-auto"
                      />
                      <span v-html="props ? props.row.D3 : ''" class="mx-auto">
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ฝาก">
                  <template v-slot="props">
                    <span
                      v-if="props.row.D4 == 1"
                      class="bg-success text-white rounded-pill"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span v-else class="bg-danger text-white rounded-pill">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ถอน">
                  <template v-slot="props">
                    <span
                      v-if="props.row.D5 == 1"
                      class="bg-success text-white rounded-pill"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span v-else class="bg-danger text-white rounded-pill">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ถอน-ออโต้">
                  <template v-slot="props">
                    <span
                      v-if="props.row.D6 == 1"
                      class="bg-success text-white rounded-pill"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </span>
                    <span v-else class="bg-danger text-white rounded-pill">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="ประเภท">
                  <template v-slot="props">
                    <span> {{ props.row.D7 }} </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" label="ยอดเงินคงเหลือ">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D8).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                      :id="'balance_' + props.row.number"
                    >
                    </span>
                    <span
                      @click="
                        getBalance(props.row.number).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      "
                      role="button"
                      class="bg-primary text-white rounded-pill ml-2 cursor-poiter"
                    >
                      <i
                        class="fa fa-refresh"
                        aria-hidden="true"
                        :id="'spin_' + props.row.number"
                      ></i>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="สถานะ">
                  <template v-slot="props">
                    <span v-if="props.row.D9 == 1" class="badge badge-success">
                      กำลังใช้งาน
                    </span>
                    <span v-else class="badge badge-danger"> ปิดใช้งาน </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="75" label="ฝากขั้นต่ำ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.min_deposit : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="75" label="ถอนขั้นต่ำ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.min_withdraw : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" fixed="right" label="จัดการ">
                  <template v-slot="props">
                    <button
                      v-if="props"
                      @click="editBank(props.row.id)"
                      type="button"
                      class="btn btn-warning btn-sm mr-2"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      v-if="props"
                      @click="deleteBank(props.row.id)"
                      type="button"
                      class="btn btn-danger btn-sm mr-2"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <div v-if="false" class="row">
      <div class="col-12">
        <h3 class="mb-3 mt-0">บันทึกโยกเงิน</h3>
        <card>
          <div class="row">
            <div class="col-6 col-sm-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationWithdrawauto.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationWithdrawauto.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col text-right">
              <!-- <el-input
                type="search"
                class="mb-3"
                placeholder="ค้นหา"
                v-model="searchQuery"
                aria-controls="datatables"
              /> -->
              <el-button
                type="danger"
                size="default"
                @click="getWithdrawautoLog()"
                :loading="loadingWithdrawauto"
                ><i class="fa fa-refresh" aria-hidden="true"></i
              ></el-button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableDataWithdrawauto"
                border
                v-loading="loadingWithdrawauto"
              >
                <el-table-column :min-width="75" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D0 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จำนวนเงิน">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="จากบัญชี">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ถึงบัญชี">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="130" label="วันที่">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="QR Code">
                  <template v-slot="props">
                    <span v-if="props.row.D5">
                      <button
                        class="btn btn-info btn-sm"
                        @click="qrshow(props ? props.row.D5 : '')"
                      >
                        <i class="fa fa-qrcode" aria-hidden="true"></i>
                      </button>
                    </span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromWithdrawauto + 1 }} to {{ toWithdrawauto }} of
                {{ totalWithdrawauto }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationWithdrawauto.currentPage"
              :per-page="paginationWithdrawauto.perPage"
              :total="paginationWithdrawauto.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <!-- <BankDevTool></BankDevTool> -->
    <el-dialog :visible.sync="modals.modalAddBank" width="35%">
      <span slot="title"> เพิ่มข้อมูล </span>
      <div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span>สถานะ:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.status"
              placeholder="เลือกสถานะ"
            >
              <span slot="label">สถานะ</span>

              <el-option
                class="select-default"
                v-for="item in selects.status"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-12 col-sm-6">
            <span>ประเภท:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.m_type"
              placeholder="เลือกประเภท"
            >
              <el-option
                class="select-default"
                v-for="item in selects.m_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="col-12 col-sm-6">
            <span>สถานะ ถอนออโต้:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.auto_withdraw"
              placeholder="เลือกเติมเครดิต"
            >
              <el-option
                class="select-default"
                v-for="item in selects.auto_withdraw"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span>ชื่อ:</span>
            <fg-input
              v-model="formBank.fname"
              type="text"
              placeholder="กรอกชื่อบัญชี"
            >
            </fg-input>
          </div>
          <div class="col-12 col-sm-6">
            <span>นามสกุล:</span>
            <fg-input
              v-model="formBank.lname"
              type="text"
              placeholder="กรอกชื่อบัญชี"
            >
            </fg-input>
          </div>

          <!-- <div class="col-12 col-sm-6">
              <span slot="label">เบอร์มือถือ:</span>
              <fg-input
                v-model="formBank.phone"
                type="text"
                placeholder="กรอกเบอร์มือถือ"
              >
              </fg-input>
            </div> -->
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span slot="label">ธนาคาร:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.name"
              placeholder="เลือกธนาคาร"
            >
              <el-option
                class="select-default"
                v-for="item in selects.bank"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-12 col-sm-6">
            <span slot="label">หมายเลขบัญชี:</span>
            <fg-input
              v-model="formBank.number"
              type="text"
              placeholder="กรอกเลขบัญชี"
              :min="10"
            >
            </fg-input>
          </div>
        </div>
        <hr class="mt-1" />
        <div class="row">
          <div class="col-12 col-sm-6">
            <span>สถานะ ฝาก:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.m_deposit"
              placeholder="เลือกสถานะ ฝาก"
            >
              <el-option
                class="select-default"
                v-for="item in selects.m_deposit"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-12 col-sm-6">
            <span>สถานะ ถอน:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.m_withdraw"
              placeholder="เลือกสถานะ ฝาก"
            >
              <el-option
                class="select-default"
                v-for="item in selects.m_withdraw"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span slot="label">ขั้นต่ำฝาก:</span>
            <fg-input
              v-model="formBank.min_deposit"
              type="number"
              placeholder="กรอกขั้นต่ำฝาก"
              :min="10"
              step="10"
            >
            </fg-input>
          </div>
          <div class="col-12 col-sm-6">
            <span slot="label">ขั้นต่ำถอน:</span>
            <fg-input
              v-model="formBank.min_withdraw"
              type="number"
              placeholder="กรอกขั้นต่ำถอน"
              :min="10"
              step="10"
            >
            </fg-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitAddBank()">
          เพิ่ม
        </button>
        <button class="btn btn-danger" @click="closeModal('modalAddBank')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalEditBank" width="35%">
      <span slot="title"> แก้ไขข้อมูลบัญชี </span>
      <div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span>สถานะ:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.status"
              placeholder="เลือกสถานะ"
            >
              <span slot="label">สถานะ</span>

              <el-option
                class="select-default"
                v-for="item in selects.status"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-12 col-sm-6">
            <span>ประเภท:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.m_type"
              placeholder="เลือกประเภท"
            >
              <el-option
                class="select-default"
                v-for="item in selects.m_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="col-12 col-sm-6">
            <span>สถานะ ถอนออโต้:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.auto_withdraw"
              placeholder="เลือกเติมเครดิต"
            >
              <el-option
                class="select-default"
                v-for="item in selects.auto_withdraw"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span>ชื่อ:</span>
            <fg-input
              v-model="formBank.fname"
              type="text"
              placeholder="กรอกชื่อบัญชี"
            >
            </fg-input>
          </div>
          <div class="col-12 col-sm-6">
            <span>นามสกุล:</span>
            <fg-input
              v-model="formBank.lname"
              type="text"
              placeholder="กรอกชื่อบัญชี"
            >
            </fg-input>
          </div>
          <div class="col-12 col-sm-6">
            <span slot="label">ธนาคาร:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.name"
              placeholder="เลือกธนาคาร"
            >
              <el-option
                class="select-default"
                v-for="item in selects.bank"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-12 col-sm-6">
            <span slot="label">หมายเลขบัญชี:</span>
            <fg-input
              v-model="formBank.number"
              type="text"
              placeholder="กรอกเลขบัญชี"
              :min="10"
            >
            </fg-input>
          </div>
          <!-- <div class="col-12 col-sm-6">
              <span slot="label">เบอร์มือถือ:</span>
              <fg-input
                v-model="formBank.phone"
                type="text"
                placeholder="กรอกเบอร์มือถือ"
              >
              </fg-input>
            </div> -->
        </div>
        <hr class="mt-1" />
        <div class="row">
          <div class="col-12 col-sm-6">
            <span>สถานะ ฝาก:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.m_deposit"
              placeholder="เลือกสถานะ ฝาก"
            >
              <el-option
                class="select-default"
                v-for="item in selects.m_deposit"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-12 col-sm-6">
            <span>สถานะ ถอน:</span>
            <el-select
              class="select-default mb-3 w-100"
              v-model="formBank.m_withdraw"
              placeholder="เลือกสถานะ ฝาก"
            >
              <el-option
                class="select-default"
                v-for="item in selects.m_withdraw"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span slot="label">ขั้นต่ำฝาก:</span>
            <fg-input
              v-model="formBank.min_deposit"
              type="number"
              placeholder="กรอกขั้นต่ำฝาก"
              :min="10"
              step="10"
            >
            </fg-input>
          </div>
          <div class="col-12 col-sm-6">
            <span slot="label">ขั้นต่ำถอน:</span>
            <fg-input
              v-model="formBank.min_withdraw"
              type="number"
              placeholder="กรอกขั้นต่ำถอน"
              :min="10"
              step="10"
            >
            </fg-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitUpdateBank()">
          บันทึก
        </button>
        <button class="btn btn-danger" @click="closeModal('modalEditBank')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalQrcode" width="20%">
      <span slot="title">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
        Qrcode
      </span>
      <div>
        <qrcode :text="qrcodevalue" class="qrcode m-auto"></qrcode>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="closeModal('modalQrcode')">
          ปิด
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  DatePicker,
  Button,
} from "element-ui";
import {
  Pagination as LPagination,
  FormGroupInput as FgInput,
} from "src/components/index";
import axios from "axios";
import Swal from "sweetalert2";
import qrcode from "vue-qrcode-component";
import moment from "moment";
import ModalTransfer from "../components/ModalTransfer.vue";

export default {
  components: {
    LPagination,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    FgInput,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button,
    qrcode,
    ModalTransfer,
  },
  data() {
    return {
      loading: true,
      loadingWithdrawauto: true,
      loadingSubmit: false,
      loadingBank: false,
      formBank: {
        id: "",
        name: "",
        number: "",
        fname: "",
        lname: "",
        phone: "",
        m_deposit: 0,
        m_withdraw: 0,
        auto_withdraw: 0,
        m_type: "",
        status: 0,
        min_deposit: 0,
        min_withdraw: 0,
      },
      selects: {
        status: [
          { value: 0, label: "ปิดใช้งาน" },
          { value: 1, label: "ใช้งาน" },
        ],
        m_type: [
          { value: "bank", label: "bank" },
          { value: "pompay", label: "pompay" },
          { value: "mtpay", label: "mtpay" },
          { value: "papayapay", label: "papayapay" },
          { value: "xpay", label: "xpay" },
        ],
        m_deposit: [
          { value: 0, label: "ปิดใช้งาน" },
          { value: 1, label: "ใช้งาน" },
        ],
        m_withdraw: [
          { value: 0, label: "ปิดใช้งาน" },
          { value: 1, label: "ใช้งาน" },
        ],
        auto_withdraw: [
          { value: 0, label: "ปิดใช้งาน" },
          { value: 1, label: "ใช้งาน" },
        ],
        bank: [
          { value: "SCB", label: "SCB" },
          { value: "KBANK", label: "KBANK" },
          { value: "KTB", label: "KTB" },
          { value: "BBL", label: "BBL" },
          { value: "TMB", label: "TMB" },
          { value: "BAY", label: "BAY" },
          { value: "GSB", label: "GSB" },
          { value: "CIMB", label: "CIMB" },
          { value: "KK", label: "KK" },
          { value: "TMB", label: "TMB" },
          { value: "SCB", label: "SCB" },
        ],
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      paginationWithdrawauto: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      modals: {
        modalQrcode: false,
        modalAddBank: false,
        modalEditBank: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      searchQuery: "",
      searchQueryWithdrawauto: "",
      tableData: [],
      tableDataWithdrawauto: [],
      timeWithdrawautoLog: "",
      qrcodevalue: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
      bank_action: [],
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },

    toWithdrawauto() {
      let highBound = this.from + this.paginationWithdrawauto.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    fromWithdrawauto() {
      this.getWithdrawautoLog();
      return (
        this.paginationWithdrawauto.perPage *
        (this.paginationWithdrawauto.currentPage - 1)
      );
    },
    totalWithdrawauto() {
      return this.paginationWithdrawauto.total;
    },
  },
  methods: {
    async getBank() {
      this.loading = true;
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getbank?search=&sort&order=desc&offset=0&limit=10&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.tableData = data.res_result.rows;
            this.pagination.total = data.res_result.total;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    addBank() {
      this.formBank.id = "";
      this.formBank.name = "";
      this.formBank.number = "";
      this.formBank.fname = "";
      this.formBank.lname = "";
      this.formBank.phone = "";
      this.formBank.m_deposit = 0;
      this.formBank.m_withdraw = 0;
      this.formBank.auto_withdraw = 0;
      this.formBank.m_type = "";
      this.formBank.status = 0;
      this.modals["modalAddBank"] = true;
    },
    async submitAddBank() {
      if (
        !this.formBank.number ||
        !this.formBank.fname ||
        !this.formBank.lname
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      let data = JSON.stringify({
        number: this.formBank.number,
        fname: this.formBank.fname,
        lname: this.formBank.lname,
        m_deposit: this.formBank.m_deposit,
        m_withdraw: this.formBank.m_withdraw,
        auto_withdraw: this.formBank.auto_withdraw,
        m_type: this.formBank.m_type,
        status: this.formBank.status,
        min_deposit: this.formBank.min_deposit,
        min_withdraw: this.formBank.min_withdraw,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/add_bank`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.getBank();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalAddBank"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    async editBank(id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getbank?search=&sort&order=desc&offset=0&limit=10&action&start_date=&end_date=&id=${id}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;
          if (data.res_code === "00") {
            this.formBank.id = data.res_result.rows[0].id;
            this.formBank.number = data.res_result.rows[0].number;
            this.formBank.fname = data.res_result.rows[0].fname;
            this.formBank.lname = data.res_result.rows[0].lname;
            this.formBank.m_deposit = Number(data.res_result.rows[0].m_deposit);
            this.formBank.m_withdraw = Number(
              data.res_result.rows[0].m_withdraw
            );
            this.formBank.auto_withdraw = Number(
              data.res_result.rows[0].auto_withdraw
            );
            this.formBank.name = data.res_result.rows[0].name;

            this.formBank.m_type = data.res_result.rows[0].m_type;
            this.formBank.status = Number(data.res_result.rows[0].status);
            this.formBank.min_deposit = Number(
              data.res_result.rows[0].min_deposit
            );
            this.formBank.min_withdraw = Number(
              data.res_result.rows[0].min_withdraw
            );
            this.modals["modalEditBank"] = true;
          }
        })
        .catch((error) => {});
    },
    async submitUpdateBank() {
      if (
        !this.formBank.number ||
        !this.formBank.fname ||
        !this.formBank.lname
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "error");
      }
      let data = JSON.stringify({
        id: this.formBank.id,
        name: this.formBank.name,
        number: this.formBank.number,
        fname: this.formBank.fname,
        lname: this.formBank.lname,
        m_deposit: this.formBank.m_deposit,
        m_withdraw: this.formBank.m_withdraw,
        auto_withdraw: this.formBank.auto_withdraw,
        m_type: this.formBank.m_type,
        status: this.formBank.status,
        min_deposit: this.formBank.min_deposit,
        min_withdraw: this.formBank.min_withdraw,
      });

      this.loadingSubmit = true;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/update_bank`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((res) => {
          this.loadingSubmit = false;
          let data = res.data;
          if (data.res_code === "00") {
            this.tableData = [];
            this.getBank();
            Swal.fire({
              title: "แจ้งเตือน",
              text: data.res_text,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            this.modals["modalEditBank"] = false;
          } else {
            return Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {
          this.loadingSubmit = false;
        });
    },
    deleteBank(id) {
      let data = JSON.stringify({
        id: id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/delete_bank`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      Swal.fire({
        title: "แจ้งเตือน",
        text: "ยืนยันการลบ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        confirmButtonClass: "btn btn-danger btn-fill",
        cancelButtonClass: "btn btn-default btn-fill",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios
            .request(config)
            .then((res) => {
              let data = res.data;
              if (data.res_code == "00") {
                this.getBank();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: data.res_text,
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        }
      });
    },
    async getBalance(number) {
      let spin = document.getElementById("spin_" + number);
      spin.classList.add("fa-spin");

      let data = JSON.stringify({
        number: number,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/get_balance`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          spin.classList.remove("fa-spin");
          let data = res.data;
          if (data.res_code === "00") {
            let balance = document.getElementById("balance_" + number);
            balance.innerHTML = data.res_result.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            });
          }
        })
        .catch((error) => {
          spin.classList.remove("fa-spin");
          return Swal.fire("แจ้งเตือน", "ไม่สามารถดึงข้อมูลได้", "error");
        });
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalAddBank"] = false;
          this.modals["modalEditBank"] = false;
        }
      });
    },

    async getWithdrawautoLog() {
      this.loadingWithdrawauto = true;
      let offset =
        this.paginationWithdrawauto.perPage *
        (this.paginationWithdrawauto.currentPage - 1);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/withdrawauto_log?search=${this.searchQueryWithdrawauto}&sort&order=desc&offset=${offset}&limit=${this.paginationWithdrawauto.perPage}&action&start_date=&end_date=`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 2000,
      };

      clearTimeout(this.timeWithdrawautoLog);

      this.timeWithdrawautoLog = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loadingWithdrawauto = false;
            const data = res.data.res_result;
            this.tableDataWithdrawauto = data.rows;
            this.paginationWithdrawauto.total = data.total;
          })
          .catch((error) => {
            this.loadingWithdrawauto = false;
          });
      }, 1000);
    },
    qrshow(value) {
      this.qrcodevalue = value;
      this.modals["modalQrcode"] = true;
    },
    async getBankAction() {
      let data = {
        date: this.date,
      };
      this.loadingBank = true;
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/bank_action`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          this.loadingBank = false;
          let data = res.data;
          if (data.res_code == "00") {
            this.bank_action = data.res_result.rows;
          }
        })
        .catch((error) => {
          this.loadingBank = false;
        });
    },
  },
  async mounted() {
    await this.getBank();
    await this.getBankAction();
  },
};
</script>
<style></style>
